<template>
  <RosterTimeBox class="w-100">
    <div class="RosterTimeBox__Time">
      <div class="RosterTimeBox__Time__Date">
        <v-edit-dialog
          class="pa-0"
          persistent
          large
          @save="handleSaveRangeDate"
        >
          <div class="RosterTimeBox__Time__DateBox">
            <span>{{ dateRangeString }}</span>
          </div>
          <template v-slot:input>
            <v-date-picker v-model="time.rangeDate" range></v-date-picker>
          </template>
        </v-edit-dialog>
      </div>
    </div>
    <!-- Time Start - End -->
    <div class="RosterTimeBox__Time mt-2">
      <div class="RosterTimeBox__Time__Hour">
        <v-menu
          ref="menuTimeStart"
          v-model="timeStartMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time.startTime"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <span class="hourItem" v-bind="attrs" v-on="on">
              {{ timeStartString }}
            </span>
          </template>
          <div
            class="d-flex flex-column"
            style="
              background-color: #03214d;
              z-index: 9999;
              text-color: #ffffff;
            "
          >
            <span
              class="white--text text-h5 pl-2 pa-1 mt-1 mb-1 w-100 d-block text-left font-weight-bold"
              >START</span
            >
            <v-time-picker
              v-model="time.startTime"
              format="24hr"
              scrollable
              @click:minute="handleSaveTimeStart"
            ></v-time-picker>
          </div>
        </v-menu>

        <span style="padding: 0 5px"> - </span>

        <v-menu
          ref="menuTimeEnd"
          v-model="timeEndMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time.endTime"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <span class="hourItem" v-bind="attrs" v-on="on">
              {{ timeEndString }}
            </span>
          </template>
          <div
            class="d-flex flex-column"
            style="
              background-color: #03214d;
              z-index: 9999;
              text-color: #ffffff;
            "
          >
            <span
              class="white--text text-h5 pl-2 pa-1 mt-1 mb-1 w-100 d-block text-left font-weight-bold"
              >END</span
            >
            <v-time-picker
              v-model="time.endTime"
              format="24hr"
              scrollable
              @click:minute="handleSaveTimeEnd"
            ></v-time-picker>
          </div>
        </v-menu>
      </div>
    </div>
    <div class="align-center mt-2" v-show="value.isFullday">
      <input
        type="checkbox"
        value="true"
        class="mr-1"
        v-model="value.isFullday"
        @change="emitModelChanged"
      />
      <span class="text-body-2 ml-1" style="font-weight: 470">All Day</span>
    </div>
    <div class="d-block mt-1">
      <v-select
        class="mt-1 w-50"
        dense
        v-model="isRepeated"
        :items="repeatOptions"
        item-text="text"
        item-value="value"
        @change="handleChangeIsRepeat"
      ></v-select>
    </div>
    <!-- Recurrence Pattern -->
    <div
      class="d-flex flex-column w-100 mt-1 recurrent-container"
      v-if="value.recurrence"
    >
      <!-- Day of week -->
      <div class="d-block">
        <div class="d-block">
          <span class="text-body-2 font-weight-bold">
            {{ $t("main.calendar.roster.timeZone.dayOfWeek") }}
            <b class="red--text">*</b>
          </span>
        </div>
        <v-checkbox
          dense
          hide-details
          v-for="dayItem in ListDayOfWeek"
          :key="dayItem.text"
          :label="dayItem.text.slice(0, 3)"
          :value="dayItem.value"
          v-model="value.recurrence.dayOfWeek"
          class="float-left"
          @change="emitModelChanged"
        ></v-checkbox>
      </div>
      <!-- Week of month -->
      <div class="d-block mt-2">
        <div class="d-block">
          <span class="text-body-2 font-weight-bold"
            >{{ $t("main.calendar.roster.timeZone.weekOfMonth") }}
            <b class="red--text">*</b></span
          >
        </div>
        <v-checkbox
          dense
          hide-details
          v-for="weekItem in ListWeekOfMonth"
          :key="weekItem.text"
          :label="weekItem.text"
          :value="weekItem.value"
          v-model="value.recurrence.weekOfMonth"
          @change="emitModelChanged"
          class="float-left"
        ></v-checkbox>
      </div>
      <!-- Day of Months -->
      <div class="d-block mt-2">
        <div class="d-block">
          <span class="text-body-2 font-weight-bold"
            >{{ $t("main.calendar.roster.timeZone.monthOfYear") }}
            <b class="red--text">*</b></span
          >
        </div>
        <v-checkbox
          dense
          hide-details
          v-for="monthItem in ListMonthOfYear"
          :key="monthItem.text"
          :label="monthItem.text.slice(0, 3)"
          :value="monthItem.value"
          v-model="value.recurrence.monthOfYear"
          @change="emitModelChanged"
          class="float-left"
        ></v-checkbox>
      </div>
    </div>
  </RosterTimeBox>
</template>

<script>
import moment from "moment";
import { RosterTimeBox } from "../css/style";
import i18n from "@/plugins/i18n";

import {
  ListDayOfWeek,
  ListWeekOfMonth,
  ListMonthOfYear,
  DefaultCalendarEventDto,
} from "@/plugins/constant";

export default {
  components: {
    RosterTimeBox,
  },
  props: {
    value: {
      type: Object,
      default: function () {
        return DefaultCalendarEventDto;
      },
    },
  },
  data: () => ({
    // Repeat options
    ListDayOfWeek,
    ListWeekOfMonth,
    ListMonthOfYear,
    // Menu of time range
    timeStartMenu: false,
    timeEndMenu: false,
    // displayed strings
    // for time, date range
    dateRangeString: "",
    timeStartString: "",
    timeEndString: "",
    // options select date, time
    time: {
      startTime: null,
      endTime: null,
      rangeDate: [],
    },
    isRepeated: false,
    allowedHoursFunc: null,
    repeatOptions: [
      {
        value: false,
        text: i18n.t("main.calendar.roster.timeZone.dosentRepeat"),
      },
      {
        value: true,
        text: i18n.t("main.calendar.roster.timeZone.reapeatEvent"),
      },
    ],
  }),
  created() {},
  methods: {
    forceUpdateModelValue(value) {
      this.renderIsRepeat();
      this.$forceUpdate();
      var { startDate, endDate, timeStart, timeEnd } = value;
      this.time.rangeDate = [startDate, endDate];
      this.renderDateRangeString();
      this.time.startTime = moment(timeStart).format("HH:mm");
      this.time.endTime = moment(timeEnd).format("HH:mm");
      this.renderTimeRangeString();
      this.$forceUpdate();
    },
    emitModelChanged() {
      this.value.startDate = moment(this.time.rangeDate[0]).format();
      this.value.endDate = moment(this.time.rangeDate[1]).format();
      this.value.timeStart = moment(
        `${moment(this.value.startDate).format("YYYY-MM-DD")} ${
          this.time.startTime
        }`
      ).format();
      this.value.timeEnd = moment(
        `${moment(this.value.startDate).format("YYYY-MM-DD")} ${
          this.time.endTime
        }`
      ).format();
      this.$emit("input", this.value);
    },
    renderIsRepeat() {
      if (this.value.recurrence) {
        this.isRepeated = true;
        return;
      }
      this.isRepeated = false;
    },
    resetOptions() {
      // Menu of time range
      this.isRepeated = false;
      // clean recurrent options
      this.value.recurrence = null;
    },
    renderDateRangeString() {
      this.time.rangeDate[0] = moment(this.time.rangeDate[0])
        .startOf("day")
        .format();
      this.time.rangeDate[1] = moment(this.time.rangeDate[1])
        .endOf("day")
        .format();
      if (moment(this.time.rangeDate[1]).isBefore(this.time.rangeDate[0])) {
        var newStart = this.time.rangeDate[1];
        this.time.rangeDate[1] = moment(this.time.rangeDate[0])
          .endOf("day")
          .format();
        this.time.rangeDate[0] = moment(newStart).startOf("day").format();
      }
      var start = moment(this.time.rangeDate[0]).format("DD/MM/YYYY");
      var end = moment(this.time.rangeDate[1]).format("DD/MM/YYYY");
      if (start == end) {
        this.dateRangeString = start;
        return;
      }
      this.dateRangeString = `${start}  -  ${end}`;
    },
    renderTimeRangeString() {
      this.timeStartString = this.time.startTime;
      this.timeEndString = this.time.endTime;
    },
    handleSaveRangeDate() {
      if (!this.time.rangeDate[0] && !this.time.rangeDate[1]) {
        this.time.rangeDate[0] = moment().startOf("day").format();
        this.time.rangeDate[1] = moment().endOf("day").format();
      }
      // always have rangeDate[0] if user select any value
      else if (!this.time.rangeDate[1]) {
        this.time.rangeDate[1] = moment(this.time.rangeDate[0])
          .endOf("day")
          .format();
      }
      this.renderDateRangeString();
      this.emitModelChanged();
    },
    handleSaveTimeStart() {
      this.$refs.menuTimeStart.save(this.time.startTime);
      this.renderTimeRangeString();
      this.emitModelChanged();
    },
    handleSaveTimeEnd() {
      this.$refs.menuTimeEnd.save(this.time.endTime);
      this.renderTimeRangeString();
      this.emitModelChanged();
    },
    handleChangeIsRepeat() {
      // if clicked no-repeat event
      if (this.isRepeated == false) {
        // clean recurrent options
        this.value.recurrence = null;
      } else {
        this.value.recurrence = {
          id: 0,
          dayOfWeek: [],
          weekOfMonth: [],
          dayOfMonth: [],
          monthOfYear: [],
          separationCount: 0,
          maxRecurrence: 0,
        };
      }
      this.emitModelChanged();
    },
  },
};
</script>

<style lang="scss">
.w-50 {
  width: 50%;
}
.recurrent-container {
  .v-input__control {
    display: inline-block;
    margin-right: 10px;
    width: 80px;
  }
  .v-input--selection-controls {
    margin: 0px;
    padding: 0px;
    margin-top: 6px;
  }
}
</style>