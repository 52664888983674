<template>
  <v-row>
    <v-col>
      <v-sheet class="h-auto">
        <div class="d-flex flex-column w-100">
          <!-- Navbar to control calendar -->
          <div class="d-block w-100">
            <v-toolbar flat>
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                {{ $t("main.calendar.today") }}
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ convertTimeLang($refs.calendar.title) }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>{{
                      $t("main.calendar.today")
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>{{
                      $t("main.calendar.week")
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>{{
                      $t("main.calendar.month")
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>{{
                      $t("main.calendar.4days")
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </div>
          <!-- Searching patient name -->
          <div class="d-block w-100">
            <v-row justify="space-between">
              <v-col lg="3" md="4" sm="12" sx="12">
                <div class="d-flex flex-column w-100">
                  <v-text-field
                    @keyup="searchPatient"
                    v-model="keySearch"
                    class="pl-4"
                    clearable
                    :label="$t('main.calendar.searchPatientName')"
                    placeholder="Enter to search"
                    append-icon="mdi-account"
                  ></v-text-field>

                  <!-- Note -->
                  <div class="d-flex w-100 justify-space-between pa-3">
                    <div
                      class="d-flex align-center ma-1"
                      v-for="(calendarStatus, index) in calendarColors"
                      :key="index"
                      style="font-family(stack): 'IBM Plex Sans', sans-serif"
                    >
                      <v-icon class="mr-1" small :color="calendarStatus.color">
                        mdi-square
                      </v-icon>
                      <span>{{ convertLangColor(calendarStatus.text) }}</span>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col lg="3" md="4" sm="12" sx="12">
                <SelectUserOptions
                  v-model="selectedUserIDs"
                  class="pa-2"
                  style="margin-top: 14px"
                />
              </v-col>
              <v-col>
                <v-btn
                  @click="openPopupCreateRosterEvent"
                  class="primary mt-3"
                  >{{ $t("main.calendar.btnCreate") }}</v-btn
                >
              </v-col>
              <v-col md="3" sm="12" sx="12">
                <div class="d-flex flex-column w-100">
                  <div
                    class="d-flex justify-end align-center w-100 text-right pa-5"
                  >
                    <span class="mr-1"
                      >{{ $t("main.calendar.totalBooking") }}:
                    </span>
                    <span class="viewItemBooking">
                      <b>{{ totalsBooking }}</b>
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-sheet>
      <v-sheet>
        <CalendarContent>
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :type="type"
            @click:date="viewDay"
            @click:more="viewDay"
            @change="handleTimeRangeChanged"
            @mousedown:event="calendarEventClicked"
          >
            <!-- @mouseup:time="endDrag"
            @mouseleave.native="cancelDrag" -->
            <template #event="{ event, timed }">
              <!-- <template #event="{ event }"> -->
              <ItemEventBooking>
                <div v-if="timed" class="timed">
                  <p class="itemEvent__name">
                    <v-icon class="mr-1" small color="#ffffff">
                      mdi-account-box-outline
                    </v-icon>
                    <b>{{ event.name }}</b>
                  </p>
                  <p>
                    <v-icon class="mr-1" small color="#ffffff">
                      mdi-clock-outline
                    </v-icon>
                    <b>{{ event.eventTimeString }}</b>
                  </p>
                  <p v-if="event.eventType == eventType.Roster">
                    <v-icon class="mr-1" small color="#ffffff">
                      mdi-account-box-outline
                    </v-icon>
                    <b>{{ event.userCreatedName }}</b>
                    <span v-if="event.attendees.length" class="ml-1">
                      (<b
                        >+{{ event.attendees.length }} guest{{
                          event.attendees.length > 1 ? "s" : ""
                        }} </b
                      >)
                    </span>
                  </p>
                </div>
                <div v-else>
                  <b>{{ event.name }}</b>
                </div>
              </ItemEventBooking>
            </template>
            <template v-slot:day-body="{ date, week }">
              <div
                class="v-current-time"
                :class="{ first: date === week[0].date }"
                :style="{ top: nowY }"
              ></div>
            </template>
          </v-calendar>
          <!-- Register Roster Dialog -->
          <RegisterRosterComponent
            ref="dialogRegisterRoster"
            @save-event-success="onRosterEventsChanged"
          />
        </CalendarContent>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import WorkListService from "@/services/work-list";
import RosterEventService from "@/services/event";
import RegisterRosterComponent from "@/components/calendar/RosterRegistration";
import SelectUserOptions from "@/components/calendar/roster/SelectBoxUser";
import moment from "moment";
import AppConstant from "@/plugins/constant";
import i18n from "@/plugins/i18n";

import {
  TypeEventCalendar,
  CalendarColor,
  EventCategory,
} from "@/plugins/constant";
import { ItemEventBooking, CalendarContent } from "./css/style";
import UserBusiness from "@/services/user";

const convertStartTime = (time) => moment(time).format("YYYY-MM-DD HH:mm");
const convertDurationTime = (time) =>
  moment(time).add(1, "hours").format("YYYY-MM-DD HH:mm");
const generateStatus = (time) => {
  let date = moment(time).format("YYYY-MM-DD");
  let currentDate = moment().format("YYYY-MM-DD");
  if (moment(currentDate).isSame(date)) {
    return moment().isBefore(time)
      ? CalendarColor.inComming
      : CalendarColor.late;
  } else if (moment(currentDate).isBefore(date)) {
    return CalendarColor.inComming;
  } else if (moment(currentDate).isAfter(date)) {
    return CalendarColor.notComing;
  }
};
export default {
  name: "CalendarPage",
  components: {
    ItemEventBooking,
    CalendarContent,
    RegisterRosterComponent,
    SelectUserOptions,
  },
  computed: {
    labelCalendar() {
      return this.type[this.typeCalendar];
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    eventItemBg() {
      return Object.keys(this.selectedEvent).length === 0
        ? ""
        : this.selectedEvent.color;
    },
  },
  watch: {
    selectedUserIDs: {
      handler() {
        this.searchEvent();
      },
    },
  },
  data() {
    return {
      selectedUserIDs: [this.$store.getters.userData.userID],
      eventType: TypeEventCalendar,
      focus: "",
      type: "week",
      typeToLabel: {
        month: i18n.t("main.calendar.month"),
        week: i18n.t("main.calendar.week"),
        day: i18n.t("main.calendar.day"),
        "4day": i18n.t("main.calendar.4days"),
      },
      totalsBooking: 0,
      events: [],
      timeStart: null,
      timeEnd: null,
      keySearch: "",
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      // view detail event
      // use for to scrollTime
      ready: false,
      calendarColors: CalendarColor,
      userOptions: [],
    };
  },
  async mounted() {
    await this.getListUserOptions();
    this.ready = true;
    this.onResize();
    this.scrollTime();
    this.updateTime();
    this.searchEvent();
    this.convertLangColor();
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    handleTimeRangeChanged({ start, end }) {
      this.timeStart = start.date;
      this.timeEnd = end.date;
      // Only render events after list users has been loaded
      if (this.userOptions.length) {
        this.searchEvent();
      }
    },
    async searchEvent() {
      this.events = [];
      this.searchBookingPatient();
      this.searchRosterEvent();
    },
    // search booking that patient registered
    async searchBookingPatient() {
      let doctorID, statusActive, eventBookingBelongDoctor;
      doctorID = this.$store.getters.userData.userID;
      statusActive = AppConstant.Active;
      // call request
      eventBookingBelongDoctor = await WorkListService.SearchBookingByDoctorID(
        doctorID,
        this.keySearch,
        statusActive,
        this.timeStart,
        this.timeEnd,
        1,
        -1
      );
      if (eventBookingBelongDoctor.error) {
        this.$toast.error("Can not get booking calendar!");
        return;
      }
      this.totalsBooking = eventBookingBelongDoctor.totals;
      let listEvent = eventBookingBelongDoctor.items.map((itemEvent) => {
        let { patientInfo, orders, admissionInfo } = itemEvent;
        let statusCalendar = generateStatus(admissionInfo.admissionTime);
        let record = {
          timed: false,
          name: patientInfo.patientName,
          eventType: this.eventType.Booking,
          eventTimeString: `${moment(admissionInfo.admissionTime).format(
            "HH:mm"
          )} - ${moment(admissionInfo.admissionTime)
            .add(1, "hours")
            .format("HH:mm")}`,
          start: convertStartTime(admissionInfo.admissionTime),
          end: convertDurationTime(admissionInfo.admissionTime),
          color: statusCalendar.color,
          statusID: statusCalendar.statusID,
          infoBooking: {
            patient: {
              name: patientInfo.patientName,
              age:
                moment().format("YYYY") -
                moment(patientInfo.dob).format("YYYY"),
              genderType: patientInfo.genderType,
              patientID: patientInfo.patientID,
            },
            order: {
              name: orders.name,
              staffPerform: orders.staffPerformName,
            },
            admission: {
              type: admissionInfo.admissionTypeName,
              chiefComplaints: admissionInfo.chiefComplaints,
            },
          },
        };
        return record;
      });
      this.events = this.events.concat(listEvent);
    },
    // search event roster
    async searchRosterEvent() {
      var eventRoster = await RosterEventService.searchByUserIDs(
        this.selectedUserIDs,
        this.timeStart,
        this.timeEnd
      );
      this.events = this.events.concat(
        eventRoster.items.map((i) => {
          var isTimed =
            !i.recurrence &&
            moment(i.startDate).add(1, "days").isBefore(i.endDate);

          var timeFormat = "YYYY-MM-DD HH:mm";
          var startDate = i.timeStart;
          var endDate = i.timeEnd;

          if (isTimed) {
            timeFormat = "YYYY-MM-DD";
            startDate = i.startDate;
            endDate = i.endDate;
          }
          var result = {
            ...i,
            timed: isTimed,
            name: i.title,
            eventType: this.eventType.Roster,
            start: moment(startDate).format(timeFormat),
            end: moment(endDate).format(timeFormat),
            color: EventCategory.find((cat) => cat.id == i.categoryID).bgColor,
            eventTimeString: `${moment(i.timeStart).format("HH:mm")} - ${moment(
              i.timeEnd
            ).format("HH:mm")}`,
            userCreatedName:
              this.userOptions.find((o) => o.userID == i.createdBy) &&
              this.userOptions.find((o) => o.userID == i.createdBy).fullName,
            attendees: i.attendees.map((a) => ({
              ...a,
              fullName:
                this.userOptions.find((o) => o.userID == i.attendeeID) &&
                this.userOptions.find((o) => o.userID == i.attendeeID).fullName,
            })),
          };
          return result;
        })
      );
    },
    calendarEventClicked(e) {
      if (e.event.eventType == this.eventType.Booking) {
        this.openPatientTab(e);
        return;
      }
      if (e.event.eventType == this.eventType.Roster) {
        this.$refs.dialogRegisterRoster.openPopupUpdate(e.event.id);
        return;
      }
    },
    // show detail event when mouseenter on event
    async openPatientTab({ event }) {
      var { patientID, name } = event.infoBooking.patient;
      var key = `/patient/${patientID}`;
      var tabData = {
        key,
        label: name,
      };
      parent.postMessage({ event: "ADD_HEADER_TAB", data: tabData }, "*");
    },
    // search booking by patient's name
    searchPatient(e) {
      var keyCode = e.keyCode;
      if (keyCode === 13) {
        // enter key
        this.searchEvent();
        return;
      }
      if (this.keySearch.length === 0) {
        this.searchEvent();
        return;
      }
    },
    async getListUserOptions() {
      var result = await UserBusiness.searchUser(
        "",
        AppConstant.StatusCodes.Active,
        "",
        1,
        1000
      );
      if (result.error) {
        this.$toast.error("Can not retrieve user list data!");
        return;
      }
      this.userOptions = result.items.map((i) => ({
        ...i,
        value: i.userID,
        text: i.fullName,
      }));
    },
    openPopupCreateRosterEvent() {
      this.$refs.dialogRegisterRoster.openPopupCreate();
    },
    mouseMove(tms) {
      let mouseTime = this.toTime(tms);
      if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouseTime, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);
        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    // when hold event and move mouse
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    endDrag() {
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }
      this.createEvent = null;
      this.createStart = null;
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    roundTime(time, down = true) {
      const roundTo = 60; //minutes;
      const roundDownTime = roundTo * 60 * 1000; // convert to number
      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    // auto draw current Time
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);
      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
    onResize() {
      if (window.innerWidth < 600) {
        this.type = "day";
      } else if (window.innerWidth > 600 && window.innerWidth < 1024) {
        this.type = "4day";
      }
    },
    onRosterEventsChanged() {
      this.searchEvent();
    },
    convertLangColor(text) {
      var val = "";
      switch (text) {
        case "In Comming":
          val = i18n.t("main.calendar.patient_status.inComming");
          break;
        case "Not Comming":
          val = i18n.t("main.calendar.patient_status.notComming");
          break;
        case "Late":
          val = i18n.t("main.calendar.patient_status.late");
      }
      return val;
    },
    convertTimeLang(text) {
      //find language default
      var string = "";
      if (localStorage.getItem("LOCATE_LANGUAGE") == "vn") {
        string = moment(text).lang("vi").format("MMMM - YYYY");
        string = string.charAt(0).toUpperCase() + string.slice(1);
      } else {
        string = moment(text).format("MMMM  YYYY");
      }
      return string;
    },
  },
};
</script>

<style lang="scss">
.viewItemBooking {
  cursor: pointer;
  color: #0052cc;
}
.v-event-timed-container {
  margin: 2px 0px 2px 0px !important;
}
.v-calendar-daily__head {
  .v-event {
    min-height: 30px;
    padding: 0px 5px;
  }
}
</style>
