import { render, staticRenderFns } from "./SelectBoxUser.vue?vue&type=template&id=22c7b89a&scoped=true&"
import script from "./SelectBoxUser.vue?vue&type=script&lang=js&"
export * from "./SelectBoxUser.vue?vue&type=script&lang=js&"
import style0 from "./SelectBoxUser.vue?vue&type=style&index=0&id=22c7b89a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22c7b89a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VAutocomplete,VAvatar,VIcon,VListItem,VListItemContent,VListItemTitle})
